import React from "react"
import Gold from "./dragndrop/Gold"
import Prioritize from "./dragndrop/Prioritize"
import Inalador1 from "./dragndrop/Inalador_1"
import Inalador2 from "./dragndrop/Inalador_2"
import Inalador3 from "./dragndrop/Inalador_3"
import Inalador4 from "./dragndrop/Inalador_4"
import Inalador5 from "./dragndrop/Inalador_5"
import Inalador6 from "./dragndrop/Inalador_6"
import Inalador7 from "./dragndrop/Inalador_7"
import Inalador8 from "./dragndrop/Inalador_8"
import Inalador9 from "./dragndrop/Inalador_9"
import Inalador10 from "./dragndrop/Inalador_10"
import Inalador11 from "./dragndrop/Inalador_11"
import Inalador12 from "./dragndrop/Inalador_12"
import Inalador13 from "./dragndrop/Inalador_13"
import Inalador14 from "./dragndrop/Inalador_14"
import Inalador15 from "./dragndrop/Inalador_15"
import Inalador16 from "./dragndrop/Inalador_16"

import interact from "interactjs"
import $ from "jquery"
import '../../css/games/dragndrop.css'

function DragDrop({game, answers, changeAnswers, id}) {

    function container_dropped(event) {
		if ($(event.target).children('.draggable-image').length > 0 && event.target.localName === 'td'){
			return_node(event.relatedTarget)
		}
		else{
			$(event.relatedTarget).slideUp(function() {
				event.relatedTarget.style = 'transform: translate(0, 0); display: none;'
				event.target.append(event.relatedTarget)
				$(event.relatedTarget).slideDown()
				event.relatedTarget.setAttribute('data-x', 0)
				event.relatedTarget.setAttribute('data-y', 0)
                hangleChanges()
			})
		}
	}
    function hangleChanges(){
        let answers = [] 
        document.querySelectorAll('.droppable-container td').forEach((el, elIndex) => {
            answers.push({
                containerId: elIndex, 
                answerId: el.lastChild ? parseInt(el.lastChild.id) : NaN
            })
        })
        changeAnswers(answers)
    }

	function dragMoveListener (event) {
		var target = event.target;
		event.target.parentNode.style.zIndex = '3';
		var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
		var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
		target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
		target.setAttribute('data-x', x);
		target.setAttribute('data-y', y);
	}
	function return_node(target){
		target.style = 'transform: translate(0, 0); transition: transform .5s; position: inherit;';
		setTimeout(function(){ target.style.transition = 'transform 0s'; }, 500);
		target.setAttribute('data-x', 0);
		target.setAttribute('data-y', 0);
	}

    interact('.droppable-container td').dropzone({
        accept: '.draggable-image',
        overlap: 'pointer',
        ondrop: container_dropped
    });

    interact('.draggable-container').dropzone({
        accept: '.draggable-image',
        overlap: 'pointer',
        ondrop: container_dropped
    });

    interact('.draggable-image').draggable({
        inertia: false,
        autoScroll: false,
        listeners: {
            move: dragMoveListener,
            // call this function on every dragend event
            end (event) {
                event.target.parentNode.style.zIndex = '1';
                if (event.relatedTarget === null){	
                    return_node(event.target);
                }
            }
        }
    })

    const checkDragnDrop = (userAnswer) =>{
        const result = []
        userAnswer.forEach((element, elID) => {
            element.containerId === element.answerId ? 
                result.push({isCorrect: true}) : 
                result.push({
                    isCorrect: false, 
                    correctAnswer: element.containerId
                })
        })
        return result
    }

    if(answers.isClicked){
        const results = checkDragnDrop(answers.answer)

        document.querySelectorAll('td').forEach((item, id) =>{
            const customClass = setClass(id, results)
            item.classList.add(customClass)
        })
    }

    function setClass(id, results){
        let classes = ""

        if (Object.keys(results).length !== 0 && results[id].isCorrect){
            classes += "correct"
            return classes
        }
        classes += "incorrect"
        return classes
    }

    const games = {
        "3": <Gold />,
        '4': <Prioritize />,
        '201': <Inalador1 />,
        '202': <Inalador2 />,
        '203': <Inalador3 />,
        '204': <Inalador4 />,
        '205': <Inalador5 />,
        '206': <Inalador6 />,
        '207': <Inalador7 />,
        '208': <Inalador8 />,
        '209': <Inalador9 />,
        '210': <Inalador10 />,
        '211': <Inalador11 />,
        '212': <Inalador12 />,
        '213': <Inalador13 />,
        '214': <Inalador14 />,
        '215': <Inalador15 />,
        '216': <Inalador16 />
    }

    return (
        <div className="game"> 
            {games[id]}
        </div>
    );
}

export default DragDrop
