import levelsInalators from './levelsInalators.json'
import { useParams, Link } from 'react-router-dom'
import React from 'react'

import DragnDrop from "./games/DragnDrop"
import WordGuess from "./games/WordGuess"

function Inalador() {
    const { id } = useParams()
    const level = levelsInalators[id]

    const [answers, setAnswers] = React.useState({
        isClicked: false,
        answer: [],
        isSecondPart: false
    })

    const handleClick = () =>{
        if (answers.isClicked){
            setAnswers(oldState => ({
                ...oldState,
                answer: {},
                isSecondPart: true,
                isClicked: false
            }))
            return
        }
    
        setAnswers(oldState => ({
            ...oldState,
            isClicked: true
        }))
    }

    function changeAnswers(newAnswers){
        setAnswers(oldState => ({
            ...oldState,
            answer: newAnswers
        }))
    }

    let game = ""

    if (!answers.isSecondPart){
        game = <DragnDrop game={level} answers={answers} changeAnswers={changeAnswers} id={id} />
    }
    else{
        game = <WordGuess game={level} answers={answers} changeAnswers={changeAnswers}/>
    }

    return(
        <div className="inalador main dragndrop">
            <header className='game_header'>
                <Link to="/" className="home">
                    <img alt='home' src='/img/icons/home.svg'/>
                </Link>
                <h2 className='game_title'>{!answers.isSecondPart 
                    ? "Classifique os inaladores de acordo com as características apresentadas, arrastando a opção correta para cada caixa de resposta. (Nível 1/2)" 
                    : "Apresente apenas um exemplo para cada um dos tópicos pedidos (inalador, molécula, marca). Digite o texto diretamente. (Nível 2/2)"}
                </h2>
                {!answers.isClicked || !answers.isSecondPart ? <button className='main_button' onClick={handleClick}>{!answers.isClicked ? "Submeter resposta" : "Próximo nível"}</button> : ""}
            </header>
            {game}
        </div>
    )

}

export default Inalador
