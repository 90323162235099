import Roda from "./components/RodaDasInaladores"
import LevelSelector from "./components/LevelSelector"
import GameSelector from "./components/GameSelector"
import Video from "./components/Video"
import Inalador from "./components/Inalador"

import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom"

function App() {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<Roda />} />
                <Route path='levels' element={<LevelSelector />}></Route>
                <Route path='games/:id' element={<GameSelector />}></Route>
                <Route path='video/:id' element={<Video />}></Route>
                <Route path='inalador/:id' element={<Inalador />}></Route>
            </Routes>
        </Router>
    )
}

export default App
