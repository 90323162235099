import React from "react"

import '../../css/games/wordGuess.css'

function WordGuess({game, answers, changeAnswers}) {
    const curretAnswers = answers.answer

    const handleChanges = (e) =>{
        const name = e.target.name
        const value = e.target.value
        curretAnswers[name] = value
        changeAnswers(curretAnswers)
    }

    const sanitizeText = (text) =>{
        if(Array.isArray(text) ){
            text = text.map(textValue =>
                textValue.toLowerCase().trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            )
        }
        else{
            text = text.toLowerCase().trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        }
        return text
    }

    const checkWords = (userAnswers) =>{
        let results = {}
        
        Object.keys(userAnswers).forEach(key => {
            const correctAnswers = sanitizeText(game[key].answer)
            const userAnswer = sanitizeText(userAnswers[key]).replaceAll(' ', '').replaceAll(',', '+').replaceAll(';', '+')
            
            if(Array.isArray(game[key].answer) ){
                correctAnswers.includes(userAnswer) ? results[key] = true : results[key] = false
            }
            else{
                userAnswer === correctAnswers ? results[key] = true : results[key] = false
            }
        })
        return {...results}
    }

    if(answers.isClicked){
        const results = checkWords(answers.answer)

        document.querySelectorAll('.puzzle-input').forEach((item, id) =>{
            results[item.name] ?  item.classList.add("correct") : item.classList.add("incorrect") 
           
        })
    }

    return (
        <div className="wordGuess game">
            <form id="form">
                <div className="anagrama-item">
                    <p className="puzzle-title">Dispositivo</p>
                    <input maxLength="100" className={"puzzle-input"} type="text" name="dispositivo" autoComplete="off" onChange={handleChanges} />
                </div>
                <div className="anagrama-item">
                    <p className="puzzle-title">Exemplo moléculas</p>
                    <input maxLength="100" className={"puzzle-input"} type="text" name="moleculas" autoComplete="off" onChange={handleChanges} />
                </div>
                <div className="anagrama-item">
                    <p className="puzzle-title">Exemplo de marcas</p>
                    <input maxLength="100" className={"puzzle-input"} type="text" name="marcas" autoComplete="off" onChange={handleChanges} />
                </div>		
            </form>
        </div>
    );
}

export default WordGuess
