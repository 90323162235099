import { Link } from 'react-router-dom'
import '../css/level_selector.css'

function LevelSelector (){

    return(
        <main className='level_selector'>
            <Link to="/" className="fixed home">
                <img alt='home' src='img/icons/home.svg'/>
            </Link>
            <h1>Desafios em doença pulmonar obstrutiva crónica: teste os seus conhecimentos</h1>
            <p style={{fontSize: "20px", textAlign: "center"}}>Selecione um dos desafios da sua preferência. Após completar a dinâmica interativa,<br/>
                submeta o seu palpite para descobrir as respostas corretas.</p>
                <br/>
            <ul style={{listStyle: "none"}}>
                
                <Link className='rounded' to="/games/0">
                    <p>PALAVRAS CRUZADAS</p>
                    <b>Definições em DPOC</b>
                    <img className='rounded' alt='game1' src='img/game(1).png'/>
                </Link>                
                <Link className='rounded' to="/games/1">
                    <p>SOPA DE LETRAS</p>
                    <b>Comorbilidades</b>
                    <img className='rounded' alt='game3' src='img/game(2).png' />
                </Link>                
                <Link className='rounded' to="/games/2">
                    <p>SOPA DE LETRAS</p>
                    <b>Encontrar os LAMA</b>
                    <img className='rounded' alt='game4' src='img/game(3).png' />
                </Link>                
                <Link className='rounded' to="/games/3">
                    <p>TABELA INCOMPLETA</p>
                    <b>Guidelines GOLD</b>
                    <img className='rounded' alt='game5' src='img/game(4).png' />
                </Link>                
                <Link className='rounded' to="/games/4">
                    <p>ORDENAR HIPÓTESES</p>
                    <b>Priorizar intervenções</b>
                    <img className='rounded' alt='game6' src='img/game(5).png' />
                </Link>            
            </ul>
        </main>
    )
}

export default LevelSelector