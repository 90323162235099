/* eslint-disable no-redeclare */
import $ from "jquery"

let row 	= false;
let column 	= false;
let id 		= '';

function keyUp(e) {
	if (e.which <= 90 && e.which >= 48)
	{
		id = e.target.id
		if( $('#'+increment_row(id)).length === 1 && !column){
			row = true;
		}
		else if( $('#'+increment_col(id)).length === 1 ){
			row = false;
			column = true;
		}
		else{
			column = false;
		}
		if (row){
			$('#'+increment_row(id)).focus().select();
			id = increment_row(id);
		}
		else if (column){
			$('#'+increment_col(id)).focus().select();
			id = increment_col(id);
		}
	}
}
function increment_col( el ){
	var chars = el.split('-');
	chars[0] = parseInt(chars[0])+1;
	return chars.join('-');
}
function increment_row( el ){
	var chars = el.split('-');
	chars[1] = parseInt(chars[1])+1;
	return chars.join('-');
}

const recognizeWord = (id, game) => {
	const letter = {
		row: parseInt( id.split("-")[0] ),
		col: parseInt( id.split("-")[1] )
	}
	
	const words = []

	game.answers.forEach((item, id) => {
		const start = {
			row: parseInt( item.start.split("-")[0] ),
			col: parseInt( item.start.split("-")[1] )
		}
		const finish = {
			row: parseInt( item.finish.split("-")[0] ),
			col: parseInt( item.finish.split("-")[1] )
		}

		if( letter.row >= start.row && 
			letter.row <= finish.row &&
			letter.col >= start.col && 
			letter.col <= finish.col){
				words.push(id)
		}
	})

	return words
}

export {keyUp, recognizeWord}