import React from "react"
import shuffleArray from "./shuffleArray"
import './gold.css'

const options = [
    <div className="flex-center draggable-image" id="0" key="0">≥ 2 exacerbações moderadas<br/>ou ≥ 1 com internamento</div>,
    <div className="flex-center draggable-image" id="1" key="1">LABA + LAMA*<br/><span><em>considerar LABA+LAMA+ICS se eos ≥ 300</em></span></div>,
    <div className="flex-center draggable-image" id="2" key="2">0 ou 1 exacerbações moderadas (sem internamento)</div>,
    <div className="flex-center draggable-image" id="3" key="3">Broncodilatador</div>,
    <div className="flex-center draggable-image" id="4" key="4">LABA + LAMA*</div>,
    <div className="flex-center draggable-image" id="5" key="5">mMRC 0-1, CAT &lt; 10</div>,
    <div className="flex-center draggable-image" id="6" key="6">mMRC ≥ 2, CAT ≥ 10</div>
]

function Gold() {
    
    const [shuffledOptions, setShuffledOptions] = React.useState()

    React.useEffect(() =>{
        setShuffledOptions (shuffleArray( options ) )
    }, [])

    return (
        <div className=" game-1">
            <div className="droppable-container">
                <table>
                    <tbody>
                        <tr>
                            <th colSpan="3" className="top-heading">TRATAMENTO FARMACOLÓGICO INICIAL</th>
                        </tr>
                    </tbody>
                </table>
                <table className="content-table">
                    <tbody>
                        <tr>
                            <td className="white-heading"></td>
                            <td colSpan="2"><span className="td-description">Grupo E</span></td>
                        </tr>
                        <tr>
                            <td className="white-heading"></td>
                            <td><span className="td-description">Grupo A</span></td>
                            <td><span className="td-description">Grupo B</span></td>
                        </tr>
                        <tr>
                            <th className="clear-heading"></th>
                            <td className="white-heading"></td>
                            <td className="white-heading"></td>
                        </tr>
                    </tbody>
                </table>
                <span className="bottom-text">*uma única técnica inalatória poderá ser mais conveniente e efetiva do que múltiplos inaladores</span>
                <span className="bottom-text definition">Definição das abreviaturas: eos: contagem de eosinófilos sanguíneos em células por mililitro; mMRC: questionário de dispneia modificado do Medical Research Council; CAT™: COPD Assessment Test™</span>
            </div>
            <div className="draggable-container text-container">
                {shuffledOptions}
            </div>
        </div>
    );
}

export default Gold
