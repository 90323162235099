import React from "react"
import '../../css/games/boggle.css'

function Boggle({game, answers, changeAnswers}) {

    const [selectedCells, setSelectedCells] = React.useState(new Set([]))
    const [resultCells, setResultCells] = React.useState({})
    const [isAdd, setIsAdd] = React.useState(true)
   
    const boggleQuestions = game.questions.map( (questionsRow, idRow) => 
        <tr key={idRow}>
            {questionsRow.map((letter, idCol) => {

                let cellClass = selectedCells.has(`${idRow}-${idCol}`) ? "cell selected" : "cell"

                if(Object.keys(resultCells).length > 0){
                    if(resultCells.hasOwnProperty(`${idRow}-${idCol}`)){
                        cellClass = resultCells[`${idRow}-${idCol}`] ? "cell correct" : "cell incorrect"
                    }
                    else{
                        cellClass = "cell"
                    }
                }

                return(
                    <td key={`${idRow}-${idCol}`} className={cellClass} id={`${idRow}-${idCol}`} >
                        {letter}
                    </td>
                )
            }
            )}
        </tr>
    )

    const selectCell = (e, isadd) =>{
        const changedTouch = e.changedTouches[0]
		const {id} = document.elementFromPoint(changedTouch.clientX, changedTouch.clientY)
        const selected = new Set ([...selectedCells])
        console.log(id, isadd, selectedCells)

        if ( selectedCells.has(id) ){
            isadd && selected.delete(id) 
            
        }
        else{
            !isadd && selected.add(id)
        }
        setSelectedCells(selected)

    }

    const touchMove = (e) =>{
        selectCell(e, isAdd) 
    }

    const touchStart = (e) =>{
        if (e.target.classList.contains('selected')){
            setIsAdd(true)
            selectCell(e, true)
            return
        }
        
        setIsAdd(false)
        selectCell(e, false)
        
    }
    const touchEnd = (e) =>{
        setIsAdd(!isAdd)
    }

    React.useEffect(()=>{
        if(Object.keys(resultCells).length > 0){
            return
        }

        const correctCells = new Set()
        game.answers.forEach(item => {
            const start = item.start.split('-')
            const finish = item.finish.split('-')

            if (item.start[0] === item.finish[0] ){
                for (let i = parseInt(start[1]); i <= parseInt(finish[1]); i++){
                    correctCells.add(start[0] + "-" + i)
                }
            }
            else{
                for (let i = parseInt(start[0]); i <= parseInt(finish[0]); i++){
                    correctCells.add(i + "-" + start[1])
                }
            }
        })
        const result = {}

        selectedCells.forEach(item =>{
            result[item] = correctCells.has(item) ? true : false
        })

        setResultCells(result)
    }, [answers.isClicked])
    

    return (
        <div className="boggle_container game">  
            {/* <div className="boggle-game" onTouchStart={touchStart} onTouchMove={touchMove} onTouchEnd={(e) => touchEnd(e, changeAnswers)}> */}
            <div className="boggle-game" onTouchMove={touchMove} onTouchStart={touchStart} onTouchEnd={touchEnd}> 
                <table>
                    <tbody>
                        {boggleQuestions}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Boggle
