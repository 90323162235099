import React from "react"
import shuffleArray from "./shuffleArray"
import './prioritize.css'

const options = [
    <div className="flex-center draggable-image" id="0" key="0">Questionar sobre adesão à terapêutica</div>,
    <div className="flex-center draggable-image" id="1" key="1">Reavaliar a técnica inalatória</div>,
    <div className="flex-center draggable-image" id="2" key="2">Avaliar a presença de comorbilidades</div>,
    <div className="flex-center draggable-image" id="3" key="3">Escalar para LABA/LAMA</div>,
    <div className="flex-center draggable-image" id="4" key="4">Fazer reabilitação respiratória</div>,
    <div className="flex-center draggable-image" id="5" key="5">Associar um Corticoide inalado</div>,
]

function Prioritize() {
    
    const [shuffledOptions, setShuffledOptions] = React.useState()

    React.useEffect(() =>{
        setShuffledOptions (shuffleArray( options ) )
    }, [])

    return (
        <div className="game game-2">
            <div className="droppable-container">
            <table>
                <tbody>
                    <tr>
                        <th className="table-header">1</th>
                        <td></td>
                    </tr>
                    <tr>
                        <th className="table-header">2</th>
                        <td></td>
                    </tr>
                    <tr>
                        <th className="table-header">3</th>
                        <td></td>
                    </tr>
                    <tr>
                        <th className="table-header">4</th>
                        <td></td>
                    </tr>
                    <tr>
                        <th className="table-header">5</th>
                        <td></td>
                    </tr>
                    <tr>
                        <th className="table-header">6</th>
                        <td></td>
                    </tr>
                </tbody>
            </table>

            </div>
            <div className="draggable-container text-container">
                {shuffledOptions}
            </div>
        </div>
    );
}

export default Prioritize
