import React from "react"
import shuffleArray from "./shuffleArray"
import './inalators.css'

const options = [
    <div className="flex-center draggable-image" id="0" key="0">Pó seco</div>,
    <div className="flex-center draggable-image" id="1" key="1">Multidose</div>,
    <div className="flex-center draggable-image" id="2" key="2">Rápida e vigorosa</div>,
    <div className="flex-center draggable-image" id="3" key="3">Câmara expansora</div>,
    <div className="flex-center draggable-image" id="4" key="4">SMI (névoa suave)</div>,
    <div className="flex-center draggable-image" id="5" key="5">Unidose</div>,
    <div className="flex-center draggable-image" id="6" key="6">Ativado pela inalação</div>,
    <div className="flex-center draggable-image" id="7" key="7">Lenta e profunda</div>,
    <div className="flex-center draggable-image" id="8" key="8">Pressurizado</div>,
    <div className="flex-center draggable-image" id="9" key="9">Respiração normal</div>,
]

function Inalador_1() {
    
    const [shuffledOptions, setShuffledOptions] = React.useState()

    React.useEffect(() =>{
        setShuffledOptions (shuffleArray( options ) )
    }, [])

    return (
        <div className="game game-200">
            <div className="droppable-container">
                <table>
                    <tbody>
                        <tr>
                            <th rowSpan="3" className="image" style={{background: "#1c8eb1"}}><img src="/img/inaladores/795-dpoc-games-inalador-11.png" alt="795-dpoc-games-inalador-11.png" /></th>
                            <th>Tipo de inalador</th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Dispensa de doses</th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Tipo de inalação</th>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="draggable-container text-container">
                {shuffledOptions}
            </div>
        </div>
    );
}

export default Inalador_1
