import levels from './levels.json'
import { useParams, Link } from 'react-router-dom'
import React from 'react'

import Boggle from "./games/Boggle"
import DragnDrop from "./games/DragnDrop"
import Crossword from "./games/Crossword"



function GameSelector() {
    const { id } = useParams()
    const level = levels[id]

    const [answers, setAnswers] = React.useState({
        isClicked: false,
        answer: []
    })

    const handleClick = () =>{
        setAnswers(oldState => ({
            ...oldState,
            isClicked: true
        }))
    }

    function changeAnswers(newAnswers){
        setAnswers(oldState => ({
            ...oldState,
            answer: newAnswers
        }))
    }

    let game = ""

    switch( level.type ){
        case "boggle": 
            game = <Boggle game={level} answers={answers} changeAnswers={changeAnswers} />
            break
        case "dragndrop": 
            game = <DragnDrop game={level} answers={answers} changeAnswers={changeAnswers} id={id} />
            break
        case "crossword": 
            game = <Crossword game={level} answers={answers} changeAnswers={changeAnswers} />
            break
        default:
            break
    }

    return(
        <div className={level.type + " main"}>
            <header className='game_header'>
                <Link to="/levels" className="home">
                    <img alt='home' src='/img/icons/back.svg'/>
                </Link>
                <h2 className='game_title'>{level.title}</h2>
                {!answers.isClicked ? <button className='main_button' onClick={handleClick}>Submeter resposta</button> : ""}
            </header>
            {game}
        </div>
    )

}

export default GameSelector
