import "../css/roda.css"
import React from "react"
import { useNavigate, Link } from "react-router-dom" 

function Roda (){
    const navigate = useNavigate()

    const symbolSegments = {
        0: "16",
        1: "15",
        2: "14",
        3: "13",
        4: "12",
        5: "11",
        6: "10",
        7: "09",
        8: "08",
        9: "07",
        10: "06",
        11: "05",
        12: "04",
        13: "03",
        14: "02",
        15: "01"
    }

    let deg = 0
    const zoneSize = 360 / Object.keys(symbolSegments).length   // deg

    const handleWin = (actualDeg) => {
        const winningSymbolNr = Math.floor(actualDeg / zoneSize)
        setTimeout(() =>{
            navigate("/inalador/2" + symbolSegments[winningSymbolNr])
        }, 2000)
    }

    const onWheelEnd = () =>{
        document.querySelector('.wheel').classList.remove('blur')
        document.querySelector('.button').style.pointerEvents = 'auto'
        document.querySelector('.wheel').style.transition = 'none'
        const actualDeg = deg % 360
        document.querySelector('.wheel').style.transform = `rotate(${actualDeg}deg)`
        handleWin(actualDeg)
    }

    const rollTheWheel = () =>{
        deg = Math.floor(5000 + Math.random() * 5000)
        document.querySelector('.button').style.pointerEvents = 'none'
        document.querySelector('.wheel').style.transition = 'all 5s ease'
        document.querySelector('.wheel').style.transform = `rotate(${deg}deg)`
        document.querySelector('.wheel').classList.add('blur')
    }

    return(
        <>
            {/* <div className="game_container">
                
                <img alt="marker" className="marker" src="img/roda/marker.png" />
                <img alt="whee" className="wheel button" src="img/roda/Roda.png" onTransitionEnd={onWheelEnd} onClick={rollTheWheel} />
                <p style={{textAlign: "center", fontSize: "20px"}}>Clique na roda dos inaladores e teste os seus conhecimentos sobre os dispositivos</p>
            </div> */}
            <div style={{maxWidth: "80vw", width: "100%"}}>
                <Link to="levels" className="main_button explorar rounded">
                    Explorar mais atividades interativas
                    <img alt="cursor" src="img/icons/touch.svg" />
                </Link>
            </div>
            <div className="video_list">
                <p style={{fontSize: "20px"}}>Assista aos vídeos educativos</p>
                <ul className="videolist">
                    <Link className="rounded" to="/video/0">
                        <div>
                            Utilizar o Genuair®<br/>
                            Técnica inalatória
                            <i className="video_code">PT-BRM-1-2021</i>
                        </div>
                        <img alt="video1" src="img/video(1).png" className="rounded" />
                    </Link>
                    <Link className="rounded" to="/video/1">
                        <div>
                            Utilizar o Easyhaler®<br/>
                            Técnica inalatória
                            <i className="video_code">PT-GIB-2-2021</i>
                        </div>
                        <img alt="video2" src="img/easyhalerv2.jpg" className="rounded" />
                    </Link>
                    <Link className="rounded" to="/video/2">
                        <div>
                            Utilizar o Easyhaler®<br/>
                            Técnica inalatória (crianças)
                            <i className="video_code">PT-GIB-6-2023</i>
                        </div>
                        <img alt="video3" src="img/video(2).png" className="rounded" />
                    </Link>
                    <Link className="rounded" to="/video/3">
                        <div>
                            Preparar o inverno na DPOC em 3 passos
                            <i className="video_code">PT-BRM-9-2023</i>
                        </div>
                        <img alt="video4" src="img/video(4).png" className="rounded" />
                    </Link>
                </ul>
            </div>
        </>
    )
}

export default Roda