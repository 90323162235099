import { Link, useParams } from 'react-router-dom'
import '../css/level_selector.css'

function Video (){
    const { id } = useParams()
    const videolist = ["https://player.vimeo.com/video/596663445", "https://player.vimeo.com/video/871150468", "https://player.vimeo.com/video/867873442", "https://player.vimeo.com/video/870575540"]

    return(
        <main className='level_selector'>
            <Link to="/" className="fixed home">
                <img alt='home' src='/img/icons/home.svg'/>
            </Link>
            <div style={{"padding": "56.25% 0 0 0", "position": "relative"}}>
                <iframe src={videolist[id]}
                    frameBorder="0" 
                    allow="autoplay" 
                    style={{"position":"absolute", "top": 0, "left": 0, "width": "100%", "height":"100%"}} 
                    title="Filme Genuair - ALTA">
                </iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
            
        </main>
    )
}

export default Video