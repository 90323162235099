import React from "react"
import {keyUp, recognizeWord} from "./functions/crossword"

import '../../css/games/crossword.css'

function Crossword({game, answers, changeAnswers}) {

    const checkCrossword = (game, userAnswer) =>{
        const result = []
        userAnswer.forEach((element, elID) => {
            const correctAnswers = game.answers[elID].answer.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            const userAnswer = element.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")

            userAnswer === correctAnswers ? 
                result.push({isCorrect: true}) : 
                result.push({
                    isCorrect: false, 
                    correctAnswer: game.answers[elID].answer
                })
        })
        return result
    }

    function handleChange (e){
        let words = [] 

        game.answers.forEach((answer, elId) => {
            const coordsStart = answer.start.split('-').map(val => parseInt(val))
            const coordsFinish = answer.finish.split('-').map(val => parseInt(val))
            let word = ""
            for (let i = coordsStart[0]; i <= coordsFinish[0]; i++) {
                for (let j = coordsStart[1]; j <= coordsFinish[1]; j++) {
                    word += document.getElementById(`${i}-${j}`).value
                }
            }
            words.push(word)
        })
        changeAnswers(words)
    }

    if(answers.isClicked){
        const results = checkCrossword(game, answers.answer)

        document.querySelectorAll('.letter').forEach((item, id) =>{
            const words = recognizeWord(item.id, game)
            words.forEach((word) => {
                const customClass = setClass(word, results)
                item.classList.add(customClass)
            })
        })
    }

    

    function setClass(id, results){
        let classes = ""

        if (Object.keys(results).length !== 0 && results[id].isCorrect){
            classes += "correct"
            return classes
        }
        classes += "incorrect"
        return classes
    }
    
    const crosswordTable = game.questions.map( (questionsRow, idRow) => 
        <tr key={idRow}>
            {questionsRow.map((letter, idCol) => 
                {
                    const isStart = game.answers.findIndex((value) => value.start === `${idRow}-${idCol}`)
                    let label = <span className="placeholder"></span>
                    if(isStart !== -1){
                        label = <span className="placeholder">{isStart + 1}</span>
                    } 
                    
                    return !letter ? <td key={`${idRow}-${idCol}`}></td> : 
                    <td key={`${idRow}-${idCol}`}>{label}
                        <input onKeyUp={keyUp} onChange={handleChange} className="letter" id={`${idRow}-${idCol}`} type="text" maxLength="1" autoComplete="off" />
                    </td>
                }  
            )}
        </tr>
    )

    const crosswordQuestions = game.answers.map( (array) => 
        <li key={array.start + array.finish}> 
        {array.question} 
            <input className="start-cell" type="hidden" value={array.start}/>
            <input className="finish-cell" type="hidden" value={array.finish}/>
        </li>
    )

    return (
        <div className="container game">
            <table className="crossword-game" cellPadding="12px">
                <tbody>
                    {crosswordTable}
                </tbody>
            </table>
            <div className="game-questions">
                <ul>
					{crosswordQuestions}
				</ul>
			</div>
        </div>
    );
}

export default Crossword;
